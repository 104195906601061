module Main exposing (Model, main)

import Api
import ApiData exposing (ApiResponse)
import Browser exposing (Document)
import Browser.Dom
import Browser.Navigation as Nav
import Json.Decode as D
import Json.Decode.Pipeline as D
import Json.Encode as E
import Model.Content as Content exposing (ChurchContent(..), RegionContent(..))
import Page
import Page.Blank as Blank
import Page.Churches.Show as ChurchesShow
import Page.Map as Map
import Page.NotFound as NotFound
import Page.Poi.Show as PoiShow
import Page.Regions.Show as RegionsShow
import Page.StaticPages.Show as StaticPagesShow
import Page.Topics.Show as TopicsShow
import Ports
import RemoteData exposing (RemoteData(..))
import Route exposing (Route)
import Session exposing (Session)
import SessionMsg as Session
import Task
import Url exposing (Url)



---- MODEL ----


type Model
    = Map Map.Model
    | ChurchesShow ChurchesShow.Model
    | PoiShow PoiShow.Model
    | RegionsShow RegionsShow.Model
    | TopicsShow TopicsShow.Model
    | StaticPagesShow StaticPagesShow.Model
    | NotFound Session
      -- A state that shows an empty Page during URL-Changes
    | Redirect Session


modelToString : Model -> String
modelToString model =
    case model of
        ChurchesShow churchModel ->
            ChurchesShow.churchModelToString churchModel

        _ ->
            ""


init : D.Value -> Url -> Nav.Key -> ( Model, Cmd Msg )
init flags url navKey =
    let
        session =
            Session.build flags navKey

        ( initModel, cmdChangeRoute ) =
            changeRouteTo (Route.fromUrl url)
                (Redirect session)
    in
    ( initModel
    , Cmd.batch [ Ports.refreshView (), cmdChangeRoute, fetchChurches session, fetchRegions session ]
    )


fetchChurches : Session -> Cmd Msg
fetchChurches session =
    Api.get session
        ( [ "churches" ]
        , Api.queryParams []
        )
        ChurchesLoaded
        (D.field "data" (D.list Content.churchContentDecoder))


fetchRegions : Session -> Cmd Msg
fetchRegions session =
    Api.get session
        ( [ "regions" ]
        , Api.queryParams []
        )
        RegionsLoaded
        (D.field "data" (D.list Content.regionContentDecoder))


changeRouteTo : Maybe Route -> Model -> ( Model, Cmd Msg )
changeRouteTo maybeRoute model =
    let
        session =
            toSession model
    in
    case maybeRoute of
        Nothing ->
            ( NotFound session, Cmd.none )

        -- Simple Pages
        Just Route.Home ->
            StaticPagesShow.init session "home" Nothing
                |> updateWith StaticPagesShow GotStaticPagesShowMsg
        
        Just Route.Map ->
            -- Never reinit Map, while it's already shown
            case model of
                Map _ ->
                    ( model, Cmd.none )

                _ ->
                    Map.init session
                        |> updateWith Map GotMapMsg

        -- Complex Pages
        Just (Route.ChurchShow id previewToken) ->
            ChurchesShow.init session id previewToken
                |> updateWith ChurchesShow GotChurchesShowMsg

        Just (Route.PoiShow churchId poiId previewToken) ->
            PoiShow.init session churchId poiId previewToken
                |> updateWith PoiShow GotPoiShowMsg

        Just (Route.TopicsShow regionId topicId previewToken) ->
            TopicsShow.init session regionId topicId previewToken
                |> updateWith TopicsShow GotTopicsShowMsg

        Just (Route.StaticPagesShow slug previewToken) ->
            StaticPagesShow.init session slug previewToken
                |> updateWith StaticPagesShow GotStaticPagesShowMsg

        Just (Route.RegionsShow id previewToken) ->
            RegionsShow.init session id previewToken
                |> updateWith RegionsShow GotRegionsShowMsg

        Just Route.Reload ->
            -- setting the url to the previous one and THEN force-reloading is impossible in elm
            ( model
            , Ports.reload ()
            )



---- UPDATE ----


type Msg
    = Ignored
    | PreChangedUrl Url
    | ChangedUrl Url Float
    | ClickedLink Browser.UrlRequest
    | GotMapMsg Map.Msg
    | GotChurchesShowMsg ChurchesShow.Msg
    | GotPoiShowMsg PoiShow.Msg
    | GotRegionsShowMsg RegionsShow.Msg
    | GotTopicsShowMsg TopicsShow.Msg
    | GotStaticPagesShowMsg StaticPagesShow.Msg
    | SessionMsg Session.Msg
    | ChurchesLoaded (ApiResponse (List ChurchContent))
    | RegionsLoaded (ApiResponse (List RegionContent))
    | MapViewportChanged E.Value
    | GotPopstatePosition (Maybe Float)


updateSession : Model -> Session -> Model
updateSession model session =
    case model of
        ChurchesShow subModel ->
            ChurchesShow { subModel | session = session }

        PoiShow subModel ->
            PoiShow { subModel | session = session }

        RegionsShow subModel ->
            RegionsShow { subModel | session = session }

        TopicsShow subModel ->
            TopicsShow { subModel | session = session }

        StaticPagesShow subModel ->
            StaticPagesShow { subModel | session = session }

        Map subModel ->
            Map { subModel | session = session }

        NotFound _ ->
            NotFound session

        Redirect _ ->
            Redirect session


toSession : Model -> Session
toSession page =
    case page of
        ChurchesShow model ->
            model.session

        PoiShow model ->
            model.session

        RegionsShow model ->
            model.session

        TopicsShow model ->
            model.session

        StaticPagesShow model ->
            model.session

        Map model ->
            model.session

        NotFound session ->
            session

        Redirect session ->
            session


hideSidebar : Model -> Model
hideSidebar model =
    model |> toSession |> Session.hideSidebar |> updateSession model


savePosition : Float -> Model -> Model
savePosition position model =
    let
        key =
            modelToString model
    in
    model |> toSession |> Session.savePosition key position |> updateSession model


update : Msg -> Model -> ( Model, Cmd Msg )
update msg model =
    case ( msg, model ) of
        ( PreChangedUrl url, _ ) ->
            ( model, Task.perform (\viewport -> ChangedUrl url viewport.viewport.y) Browser.Dom.getViewport )

        ( ChangedUrl url y, _ ) ->
            let
                ( newModel, newRouteCmd ) =
                    changeRouteTo (Route.fromUrl url) (model |> hideSidebar |> savePosition y)
            in
            ( newModel
            , Cmd.batch
                [ newRouteCmd
                , Ports.trackPageView ()
                , Ports.refreshView ()
                ]
            )

        ( ClickedLink urlRequest, _ ) ->
            case urlRequest of
                Browser.Internal url ->
                    ( model
                    , Nav.pushUrl (model |> toSession |> .navKey) (Url.toString url)
                    )

                Browser.External href ->
                    ( model, Nav.load href )

        ( GotMapMsg subMsg, Map subModel ) ->
            Map.update subMsg subModel
                |> updateWith Map GotMapMsg

        ( GotMapMsg _, _ ) ->
            ( model, Cmd.none )

        ( GotChurchesShowMsg subMsg, ChurchesShow subModel ) ->
            ChurchesShow.update subMsg subModel
                |> updateWith ChurchesShow GotChurchesShowMsg

        ( GotChurchesShowMsg _, _ ) ->
            ( model, Cmd.none )

        ( GotPoiShowMsg subMsg, PoiShow subModel ) ->
            PoiShow.update subMsg subModel
                |> updateWith PoiShow GotPoiShowMsg

        ( GotPoiShowMsg _, _ ) ->
            ( model, Cmd.none )

        ( GotRegionsShowMsg subMsg, RegionsShow subModel ) ->
            RegionsShow.update subMsg subModel
                |> updateWith RegionsShow GotRegionsShowMsg

        ( GotRegionsShowMsg _, _ ) ->
            ( model, Cmd.none )

        ( GotTopicsShowMsg subMsg, TopicsShow subModel ) ->
            TopicsShow.update subMsg subModel
                |> updateWith TopicsShow GotTopicsShowMsg

        ( GotTopicsShowMsg _, _ ) ->
            ( model, Cmd.none )

        ( GotStaticPagesShowMsg subMsg, StaticPagesShow subModel ) ->
            StaticPagesShow.update subMsg subModel
                |> updateWith StaticPagesShow GotStaticPagesShowMsg

        ( GotStaticPagesShowMsg _, _ ) ->
            ( model, Cmd.none )

        ( SessionMsg subMsg, _ ) ->
            let
                ( newSession, sessionCmd ) =
                    Session.update subMsg (toSession model)
            in
            ( updateSession model newSession, Cmd.map SessionMsg sessionCmd )

        ( Ignored, _ ) ->
            ( model, Cmd.none )

        ( ChurchesLoaded res, _ ) ->
            let
                ( webDataChurches, _, _ ) =
                    Api.handleResponse
                        res
                        identity
            in
            ( updateSession model (Session.setChurches webDataChurches (toSession model))
            , Cmd.none
            )

        ( RegionsLoaded res, _ ) ->
            let
                ( webDataRegions, _, _ ) =
                    Api.handleResponse
                        res
                        identity
            in
            ( updateSession model (Session.setRegions webDataRegions (toSession model))
            , Cmd.none
            )

        ( MapViewportChanged json, _ ) ->
            let
                newSession =
                    Session.setMapViewport json (toSession model)
            in
            ( updateSession model newSession, Cmd.none )

        ( GotPopstatePosition position, _ ) ->
            ( model |> toSession |> Session.setPopstatePosition position |> updateSession model, Cmd.none )



---- VIEW ----


view : Model -> Document Msg
view model =
    let
        viewPage page toMsg config =
            Page.view
                (toSession model)
                toMsg
                SessionMsg
                page
                config
    in
    case model of
        {-
           Boring Routes
        -}
        Redirect _ ->
            viewPage Page.Other (\_ -> Ignored) Blank.view

        NotFound _ ->
            viewPage Page.Other (\_ -> Ignored) NotFound.view

        {-
           Interesting Routes
        -}
        Map subModel ->
            viewPage Page.Map GotMapMsg (Map.view subModel)

        ChurchesShow subModel ->
            viewPage Page.ChurchShow GotChurchesShowMsg (ChurchesShow.view subModel)

        PoiShow subModel ->
            viewPage Page.PoiShow GotPoiShowMsg (PoiShow.view subModel)

        RegionsShow subModel ->
            viewPage Page.RegionsShow GotRegionsShowMsg (RegionsShow.view subModel)

        TopicsShow subModel ->
            viewPage Page.TopicsShow GotTopicsShowMsg (TopicsShow.view subModel)

        StaticPagesShow subModel ->
            case subModel.slug of
                "home" -> 
                    viewPage Page.Home GotStaticPagesShowMsg (StaticPagesShow.view subModel)
                _ -> 
                    viewPage Page.StaticPagesShow GotStaticPagesShowMsg (StaticPagesShow.view subModel)



---- PROGRAM ----


main : Program D.Value Model Msg
main =
    Browser.application
        { init = init
        , onUrlChange = PreChangedUrl
        , onUrlRequest = ClickedLink
        , subscriptions = subscriptions
        , update = update
        , view = view
        }


updateWith :
    (subModel -> Model)
    -> (subMsg -> Msg)
    -> ( subModel, Cmd subMsg, Cmd Session.Msg )
    -> ( Model, Cmd Msg )
updateWith toModel toMsg ( subModel, subCmd, cmd ) =
    ( toModel subModel
    , Cmd.batch [ Cmd.map toMsg subCmd, Cmd.map SessionMsg cmd ]
    )



-- SUBSCRIPTIONS


gotPopstatePosition : E.Value -> Msg
gotPopstatePosition value =
    value
        |> D.decodeValue D.float
        |> Result.toMaybe
        |> GotPopstatePosition


subscriptions : Model -> Sub Msg
subscriptions _ =
    Sub.batch
        [ Ports.groundplanPoiSelected ChurchesShow.groundplanPoiSelected |> Sub.map GotChurchesShowMsg
        , Ports.mapChurchSelected Map.mapChurchSelected |> Sub.map GotMapMsg
        , Ports.mapRegionSelected Map.mapRegionSelected |> Sub.map GotMapMsg
        , Ports.mapViewportChanged MapViewportChanged
        , Ports.consentNone (always Session.consentNone) |> Sub.map SessionMsg
        , Ports.consentAll (always Session.consentAll) |> Sub.map SessionMsg
        , Ports.gotPopstatePosition gotPopstatePosition
        ]
